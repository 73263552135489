// Place all the styles related to the recipes controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

// Detecting if button collapsed,
// pulled from: https://stackoverflow.com/a/31967516/7748511
[data-toggle="collapse"].collapsed .if-not-collapsed {
  display: none;
}
[data-toggle="collapse"]:not(.collapsed) .if-collapsed {
  display: none;
}

// Holds the recipe summary (not sure how to describe this, 
// see the box in the top right corner when viewing a recipe)
table#recipe_info td, table#recipe_info th {
  border-collapse: collapse;
  border: 2px solid black;
  padding: 7px;
  text-align: center;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

form#new_ingredient {
  div.card-body {
    padding: 0rem 1.25rem 1.25rem 1.25rem !important;
  }
  div.card-title {
    margin-top: 0.75rem;
    margin-bottom: 0px !important;
  }
}

form#new_utensil {
  div.card-body {
    padding: 0rem 1.25rem 1.25rem 1.25rem !important;
  }
  div.card-title {
    margin-top: 0.75rem;
    margin-bottom: 0px !important;
  }
}

#gallery {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  div {
    max-width: 100px;
    text-align: center;
    margin: 5px;
    // border: 2px solid black;
    // border-radius: 5px;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}