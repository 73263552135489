// Place all the styles related to the home controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

div.flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

ul.multiselect-container input[type="checkbox"] {
  display: none;
}
ul.multiselect-container label {
  display: inline-block;
}

h2#results {
  display: none;
}