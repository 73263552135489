@import "bootstrap";
@import "recipes";
@import "home";

a.navbar-brand {
  padding: 0px !important;
}

#paginate-container {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 0px) and (max-width: 574px) {
  .desktop { display: none; }
  .tablet { display: none; }
}
@media screen and (min-width: 575px) and (max-width: 766px) {
  .desktop { display: none; }
}
@media screen and (min-width: 767px) and (max-width: 990px) {
  .mobile { display: none; }
}
@media screen and (min-width: 992px) {
  .mobile { display: none; }
}

#gallery img {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
}
#gallery img:hover {
  filter: none; /* IE6-9 */
  -webkit-filter: grayscale(0); /* Google Chrome, Safari 6+ & Opera 15+ */
}
